import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" />
      <article className="post-content page-template no-image">
        <center>
          <iframe src="https://play.dub.ninja/public/dub_ninja/embed"></iframe>
        </center>
        <div className="post-content-body">
          <h1 class="page-head-title">This is Dub Ninja</h1>
          <p></p>
          <p></p>
          <div class="post-content-body">
            <p>
              Dub Ninja is a{" "}
              <strong>
                curated digital{" "}
                <strong>
                  <strong>
                    <strong>
                      <strong>radio station</strong>
                    </strong>
                  </strong>
                </strong>
              </strong>{" "}
              to dream, work, think and live. Our high-quality livestream is an
              aesthetic <strong>harmonic mix</strong> of exceptional{" "}
              <strong>outsider / hypnotic dub techno</strong>, mixed seamlessly
              together by AI 24/7.
            </p>
            <blockquote>
              <em>
                <em>
                  <em>
                    <em>Dub Ninja in numbers:</em>
                  </em>
                </em>
              </em>
              <br></br>
              <em>
                <em>
                  <em>
                    <em>– launched independently in 2019</em>
                  </em>
                </em>
              </em>
              <br></br>
              <em>
                <em>
                  <em>
                    <em>– </em>
                  </em>
                  6K+
                  <em>
                    <em> unique listeners from 89 countries</em>
                  </em>
                </em>
              </em>
              <br></br>
              <em>
                <em>
                  <em>
                    <em>– </em>
                  </em>
                </em>
                500
                <em>
                  +
                  <em>
                    <em>
                      {" "}
                      favourites on{" "}
                      <a
                        href="https://tunein.com/radio/Dub-Ninja-s252187/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TuneIn
                      </a>{" "}
                      and counting
                    </em>
                  </em>
                </em>
              </em>
              <br></br>– lossless and 320 kb/s AAC+ stream with HD audio only
            </blockquote>
            <p>
              Deep, magnetic and slightly hypnotic beats that gradually change
              much like tectonic shifts from one state to another enable Dub
              Ninja to be a{" "}
              <strong>
                <strong>
                  <strong>
                    <strong>perfect background</strong>
                  </strong>
                </strong>
              </strong>{" "}
              for your day.
            </p>
            <p>
              Unlike others, Dub Ninja doesn’t require your constant attention,
              allowing you to{" "}
              <strong>
                <strong>
                  <strong>
                    <strong>focus on things</strong>
                  </strong>
                </strong>
              </strong>{" "}
              you find important right now; however, if you do decide to listen
              carefully, you will uncover a whole new dimension of smartly
              programmed grooves and synths by producers who are the true
              masters of sharp, precise and{" "}
              <strong>
                <strong>
                  <strong>
                    <strong>minimalistic aesthetic</strong>
                  </strong>
                </strong>
              </strong>{" "}
              in music composition.
            </p>
            <p>
              Explore{" "}
              <strong>
                elegant, sophisticated, top-notch contemporary dub techno
              </strong>{" "}
              in high definition with Dub Ninja.
            </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
